import whiteListAbi from "./WhiteListAbi.json"
export const WhiteListContractInstance="0x94281fBBdD064B48B5d93E807b71bE9ADe3535EC"
export const WETHAddress="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"

const devConfig = {
    BaseURL: 'https://dev-apis.plutus.bot/api',
    webUrl: 'https://dev.plutus.bot/',
    WhiteListContractInstance: '0x94281fBBdD064B48B5d93E807b71bE9ADe3535EC',
    whiteListAbi: whiteListAbi,
  }
const liveConfig = {
    BaseURL: 'https://apis.plutus.bot/api',
    webUrl: 'http://plutus.bot/',
    WhiteListContractInstance: '0x94281fBBdD064B48B5d93E807b71bE9ADe3535EC',
    whiteListAbi: whiteListAbi,
  }
  
  export const configURl =
    typeof window !== 'undefined' && window.location.origin.includes('dev')
      ? devConfig
      : typeof window !== 'undefined' && window.location.origin.includes('localhost')
        ? devConfig
        : liveConfig
